import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoLarge from '../../assets/logos/logowhite.svg';
import './LoginPage.css';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { login as loginApi } from '../../api/auth';
// import useCsrfToken from '../../hooks/useCsrfToken';

const ResellerLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    // const csrfToken = useCsrfToken(); // Call the hook here to get the CSRF token directly

    // Use the `useApi` hook for login, which includes CSRF handling
    const { execute: login, loading, error } = useApiWithCsrf(loginApi, true);


    

    const getActivePage = () => {
        switch (location.pathname) {
          case '/':
            return 'home';
          case '/activation':
            return 'activation';
          case '/manage-playlist':
            return 'manage-playlist';
          case '/reseller/description':
            return 'reseller';
          case '/contact':
            return 'contact';
          default:
            return 'home';
        }
      };

      const [activePage, setActivePage] = useState(getActivePage());
   
  
    const handleLinkClick = (page) => {
      setActivePage(page);
    };

    


      useEffect(() => {
        setActivePage(getActivePage());
      }, [location]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
           const response =  await login({ email, password }); // Automatically includes CSRF via `useApi`
           console.log(response);
           if(response && response?.data?.message ==='Login successful')
           navigate('/reseller/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <div>
      <nav className="navbar">
        <div className="container">
          <div className="nav-logo-lgn">
            <Link to="/" onClick={() => handleLinkClick("home")}>
              <img src={logoLarge} alt="Logo" />
            </Link>
          </div>
        </div>
      </nav>

      <div className="reseller-login-container">
        <div className="login-box">
          <h2>RESELLER SIGNIN</h2>

          {/* {error && <div className="error-message">{error}</div>} */}
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleLogin}>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <div className="login-links">
              <Link to="/reseller/forgot-password" className="forgot-password">
                Forgot password?
              </Link>
              <Link to="/reseller/register" className="register-link">
                Register
              </Link>
            </div>

            <button type="submit" className="signin-button" disabled={loading}>
              {loading ? 'Signing in...' : 'Signin'}
            </button>
          </form>
        </div>
      </div>
    </div>
    );
};

export default ResellerLogin;
