import React, { useEffect, useState } from 'react';
import './CheckMac.css';
import { FaSearch } from 'react-icons/fa';
import useApi from '../../hooks/useApi';
import { getClientByMac } from '../../api/reseller';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { formatDate } from '../../utils/dateHelpers';

function CheckMac() {
    const [macAddress, setMacAddress] = useState('');
    const [clientDetails, setClientDetails] = useState(null);
    const [error, setError] = useState('');
    const { execute: checkDevice, loading: infoLoading } = useApiWithCsrf(getClientByMac);
    const [isExpired, setIsExpired] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    //   const { execute, loading } = useApi(getClientByMac);

    useEffect(() => {
        const tisExpired = clientDetails?.currentPlan === 'EXPIRED';
        const tisTrial = clientDetails?.currentPlan === 'TRIAL';
        setIsExpired(tisExpired)
        setIsTrial(tisTrial)
      }, [clientDetails]);

    // Handle MAC address input
    const handleMacAddressChange = (e) => {
        let value = e.target.value.replace(/[^a-fA-F0-9]/g, '');
        if (value.length > 12) value = value.substring(0, 12);
        const formattedMac = value.match(/.{1,2}/g)?.join(':') || '';
        setMacAddress(formattedMac);
    };

    const handleSearch = async () => {
        setError('')
        if (!macAddress.trim() || macAddress.length!==17) {
            setError('Please enter a valid MAC address.');
            return;
        }

        setError('');
        setClientDetails(null); // Clear previous results

        try {
            const details = await checkDevice(macAddress);
            console.log(details);
            
            setClientDetails(details);
           
        } catch (err) {
            console.log(err);
            
            setError(err?.response?.data?.message || 'Failed to fetch client details.');
        }
    };

    return (
        <div className="check-mac-container">
            <div className='check-mack-box'>
                <h2>Check MAC Address</h2>
                <div className="mac-search">
                    <input
                        type="text"
                        placeholder="Enter MAC address..."
                        value={macAddress}
                        onChange={handleMacAddressChange}
                    />
                    <button onClick={handleSearch} disabled={infoLoading}>
                        <FaSearch />
                    </button>
                </div>

                {error && <div className="error">{error}</div>}

                {clientDetails && (
                    <div className="client-details">
                        <h3>Device Information</h3>
                        <p><strong>MAC:</strong> {clientDetails.mac}</p>
                        <p><strong>System:</strong> {clientDetails.system}</p>
                        <p><strong>Version:</strong> {clientDetails.version}</p>
                        <p><strong>Activated:</strong> {clientDetails.activated ? 'Yes' : 'No'}</p>
                        <p  ><strong>Current Plan:</strong> <span className={
                            isExpired ? 'plan-tag expired' : isTrial ? 'plan-tag trial' : 'plan-tag'
                        }> {clientDetails.currentPlan}</span> </p>
                        <p><strong>Expiration Date:</strong> {formatDate(clientDetails.expirationDate)}</p>
                    </div>
                )}
            </div>

        </div>
    );
}

export default CheckMac;
