import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { resendActivationEmail, activateReseller } from '../../api/reseller';
import './ResellerActivation.css';

const ResellerActivation = () => {
  const { token } = useParams(); // Get the activation token from URL
  const navigate = useNavigate();

  // State variables
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [resendMessage, setResendMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const hasActivated = useRef(false); // Prevent multiple API calls

  // **Activate Reseller Account**
  useEffect(() => {
    const activateAccount = async () => {
      if (!token || hasActivated.current) return; // Ensure token exists and activation isn't repeated

      hasActivated.current = true; // Mark activation as triggered

      try {
        setLoading(true);
        console.log("call activated " + token);
        
        const response = await activateReseller(token); // Call the activation API
        setMessage(response.message || 'Your account has been activated successfully!');
      } catch (error) {
        setMessage(error.response?.data?.message || 'Invalid or expired activation token.');
      } finally {
        setLoading(false);
      }
    };

    activateAccount(); // Trigger activation
  }, [token]);

  // **Handle Resend Activation Email**
  const handleResendActivation = async () => {
    if (!email) {
      setResendMessage('Please enter your email.');
      return;
    }

    try {
      setResendLoading(true);
      setResendMessage('');
      const response = await resendActivationEmail(email); // Call the resend activation email API
      setResendMessage(response.message || 'Activation email sent successfully.');
    } catch (error) {
      setResendMessage(error.response?.data?.message || 'Failed to resend activation email.');
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <div className="activation-container">
      <div className="activation-card">
        <div className="activation-header">
          <h1>
            {loading
              ? 'Processing...'
              : message.includes('Invalid')
              ? 'Activation Failed'
              : 'Account Activated'}
          </h1>
        </div>
        {message.includes('Invalid') ? (
          <div className="activation-content">
            <p>{message}</p>
            <h3>Resend Activation Email</h3>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="activation-input"
            />
            <button
              onClick={handleResendActivation}
              disabled={resendLoading}
              className="activation-button"
            >
              {resendLoading ? 'Resending...' : 'Resend Email'}
            </button>
            {resendMessage && <p className="resend-message">{resendMessage}</p>}
          </div>
        ) : (
          <div className="activation-content">
            <p>
              Your account has been activated successfully. You can now log in
              and start using the platform.
            </p>
          </div>
        )}
        <div className="activation-footer">
          <button
            className="activation-button"
            onClick={() => navigate('/')}
          >
            Go to Homepage
          </button>
          <button
            className="activation-button"
            onClick={() => navigate('/reseller/dashboard')}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResellerActivation;
