import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Description.css';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import Spinner from '../../components/Spinners/Spinner'
import { checkSession } from '../../api/auth';


function Description() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoadingSession, setisLoadingSession] = useState(true);

  // const { execute: verifySession } = useApiWithCsrf(checkSession, true); // Hook for verifying session
  const [isSessionValidated, setIsSessionValidated] = useState(false); // Track validation status

  // useEffect(() => {
  //   const validateSession = async () => {
  //     try {
  //       const response = await verifySession(); // Call the session check API
  //       if (response?.isAuthenticated) {
  //         navigate('/reseller/dashboard'); // Redirect to dashboard if session is valid
  //       } else {
  //         setShow(true); // Show the description page if session is invalid
  //       }
  //     } catch (error) {
  //       console.error('Session validation failed:', error);
  //       setShow(true); // Show the description page on error
  //     } finally {
  //       setIsSessionValidated(true); // Mark session validation as complete
  //     }
  //   };

  //   if (!isSessionValidated) {
  //     validateSession(); // Ensure session validation is called only once
  //   }
  // }, [ navigate]);

// Function to verify session and redirect if invalid
// const verifySession = async () => {
//   const isAuthenticated = await checkSession();
//   setIsSessionValidated(isAuthenticated)
//   if (!isAuthenticated) {
//     navigate('/reseller/login');
//   }

//   if (isAuthenticated) {
//     navigate('/reseller/dashboard');
//   }
// };

const verifySession = async () => {
  try {
    const responce =await checkSession()
    const { isAuthenticated } = responce?.data ;
    setIsSessionValidated(isAuthenticated)
    if (isAuthenticated) {
      navigate('/reseller/dashboard');
    } 
    else {
      setisLoadingSession(false)
    }
  } catch (error) {
    console.error('Session validation failed:', error);
    setisLoadingSession(false)
    // navigate('/reseller/login'); // Redirect to login on error
  }
};


useEffect(() => {
  // Listen for visibility change events
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      verifySession(); // Check session when tab becomes active
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Initial session check on load
  verifySession();

  // Cleanup listener on component unmount
  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [navigate]);


  return (
    <div>
      {(!isSessionValidated && !isLoadingSession) ? (
        <div className="reseller-landing-container">
          <div className="reseller-landing">
            <h1>Join the best and the #1 Media player for smart TVs</h1>
            <p>We offer big discounts for resellers, with great features and functionality!</p>
            <div className="buttons">
              <button className="btn-register" onClick={() => navigate('/reseller/register')}>Register</button>
              <button className="btn-login" onClick={() => navigate('/reseller/login')}>Login</button>
            </div>

            <hr className="brl" />

            <section className="features">
              <h2>Reseller Features</h2>
              <div className="feature-cards">
                <div className="feature-card">
                  <h3>Simple Interface</h3>
                  <p>User-friendly interface for resellers</p>
                </div>
                <div className="feature-card">
                  <h3>Premium Panel</h3>
                  <p>Easy, rich, and intuitive control panel</p>
                </div>
              </div>
            </section>

            <hr className="brl" />

            <section className="credits-system">
              <h2>Credits System</h2>
              <p>Resellers can now purchase any number of credits they desire, and use these credits towards one-year or lifetime activations.</p>
              <div className="credit-conversion">
                <div className="credit-box">
                  <div className="credit-period">1 YEAR</div>
                  <div className="credit-equals">=</div>
                  <div className="credit-value">1 CREDIT</div>
                </div>
                <div className="credit-box">
                  <div className="credit-period">LIFETIME</div>
                  <div className="credit-equals">=</div>
                  <div className="credit-value">2.5 CREDITS</div>
                </div>
              </div>
              <div className="credit-table">
                <table cellSpacing={2}>
                  <thead>
                    <tr>
                      <th>Credits Plan</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td>1 {'->'} 10 Credits</td><td>4,69€</td></tr>
                    <tr><td>11 {'->'} 50 Credits</td><td>4,22€</td></tr>
                    <tr><td>51 {'->'}  100 Credits</td><td>3,75€</td></tr>
                    <tr><td>101 {'->'}  200 Credits</td><td>3,28€</td></tr>
                    <tr><td>201 {'->'}  500 Credits</td><td>2,34€</td></tr>
                    <tr><td>501 {'->'}  1000 Credits</td><td>1,87€</td></tr>
                    <tr><td>1001 {'->'}  ∞ </td><td>1,41€</td></tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      ): (
        <center>
          <Spinner />
          </center>
      )}
    </div>
  );
}

export default Description;
