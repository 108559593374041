// import axios from 'axios';

// export const apiRequest = async (method, url, data = {}) => {
//     // Extract the CSRF token from the cookies
//     const csrfToken = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1];
//     const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
//     if (!csrfToken) {
//       throw new Error('CSRF token not found.');
//     }
  
//     try {
//       return await axios({
//         method,
//         // url: `https://svisiontv.com/api${url}`,
//         url: `${backendUrl}/api${url}`,
//         data,
//         headers: {
//           'x-csrf-token': csrfToken,
//         },
//         withCredentials: true, // Include cookies with the request
//       });
//     } catch (error) {
//       if (error.response) {
//         const { status, data } = error.response;
  
//         // Handle token expiration or invalid token
//         if (status === 401 || data?.message === 'Invalid token.') {
//           console.error('Session expired. Redirecting to login...');
//           window.location.href = '/reseller/login'; // Redirect to login page
//         }
  
//         // Pass the response message to the caller
//         throw new Error(data?.message || `Request failed with status ${status}`);
//       }
  
//       // For other types of errors (e.g., network errors)
//       throw new Error(error.message || 'An unexpected error occurred.');
//     }
//   };
  
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// export const apiRequest = async (method, url, data = {}) => {
//   console.log('API request method');

//   const csrfToken = axios.defaults.headers.common['X-CSRF-Token'];
  
//   if (!csrfToken) {
//     console.error('❌ CSRF token is missing');
//     throw new Error('CSRF token not found.');
//   }

//   try {
//     console.log(`Start request with token: ${csrfToken}`);

//     return await axios({
//       method,
//       url: `${backendUrl}/api${url}`,
//       data,
//       headers: {
//         'X-CSRF-Token': csrfToken,
//       },
//       withCredentials: true, // Ensure cookies are sent with the request
//     });
//   } catch (error) {
//     if (error.response) {
//       const { status, data } = error.response;

//       // Handle token expiration or invalid token
//       if (status === 401 || data?.message === 'Invalid token.') {
//         console.error('Session expired. Redirecting to login...');
//         window.location.href = '/reseller/login';
//       }

//       throw new Error(data?.message || `Request failed with status ${status}`);
//     }

//     throw new Error(error.message || 'An unexpected error occurred.');
//   }
// };

// Ensure CSRF token is always included
export const apiRequest = async (method, url, data = {}) => {
  console.log(`📤 API Request: ${method.toUpperCase()} ${backendUrl}/api${url}`);

  try {
    // Fetch the CSRF token dynamically if missing
    let csrfToken = axios.defaults.headers.common['X-CSRF-Token'];

    if (!csrfToken) {
      console.warn('⚠️ CSRF token is missing. Fetching...');
      try {
        const csrfResponse = await axios.get(`${backendUrl}/api/csrf-token`, { withCredentials: true });
        csrfToken = csrfResponse.data.csrfToken;
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
        console.log(`✅ CSRF token fetched: ${csrfToken}`);
      } catch (csrfError) {
        console.error('❌ Failed to fetch CSRF token:', csrfError.message);
        throw new Error('CSRF token not found.');
      }
    }

    // Make API request with CSRF token and credentials
    const response = await axios({
      method,
      url: `${backendUrl}/api${url}`,
      data,
      headers: {
        'X-CSRF-Token': csrfToken,
      },
      withCredentials: true, // ✅ Ensure cookies (JWT) are sent
    });

    console.log('✅ API Response:', response.data);
    return response;
  } catch (error) {
    console.error('❌ API Request Failed:', error.response?.data?.message || error.message);

    // Redirect to login if session expires
    if (error.response?.status === 401 || error.response?.data?.message === 'Invalid session') {
      console.warn('⚠️ Session expired. Redirecting to login...');
      // window.location.href = '/reseller/login';
    }

    throw error;
  }
};