import axios from 'axios';
import { apiRequest } from './apiRequest';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const API_URL = `${backendUrl}/api/auth`;
// const API_URL = 'http://localhost:5000/api/auth';
// const API_URL = 'https://svisiontv.com/api/auth';


// export const login = async (data) => {
//   const response = await axios.post(`${API_URL}/login`, data, { withCredentials: true });
//   return response.data;
// };

// export const registerAcc = async (data) => {
//   const response = await axios.post(`${API_URL}/register`, data, { withCredentials: true });
//   return response.data;
// };

// export const logout = async () => {
//   const response = await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
//   return response.data;
// };

// export const forgotPassword = async (data) => {
//   return await axios.post(`${API_URL}/forgot-password`, data);
// };


// // Check if the session is valid
// export const checkSession = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/session`, { withCredentials: true });
//   return response.data;
//   } catch (error) {
    
//   }
  
// };


// export const resetPassword = async (data) => {
//   return await axios.post(`${API_URL}/reset-password`, data);
// };



export const login = async (data) => {
  return await apiRequest('post', '/auth/login', data);
};

export const registerAcc = async (data) => {
  return await apiRequest('post', '/auth/register', data);
};

export const logout = async () => {
  return await apiRequest('post', '/auth/logout');
};

export const forgotPassword = async (data) => {
  return await apiRequest('post', '/auth/forgot-password', data);
};

// Check if the session is valid
export const checkSession = async () => {
  try {
    return await apiRequest('get', '/auth/session');
  } catch (error) {
    console.error("Session check failed:", error);
    throw error;
  }
};

export const resetPassword = async (data) => {
  return await apiRequest('post', '/auth/reset-password', data);
};
