import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navigation/Navbar';
import Home from './pages/Home/Home';
import Footer from './components/Layout/Footer';
import LoginPage from './pages/LoginPage/LoginPage';
import Description from './pages/Description/Description';
import ResellerDashboard from './pages/ResellerDashboard/ResellerDashboard';
import Activation from './pages/Activation/Activation';
import ManagePlaylist from './pages/ManagePlaylist/ManagePlaylist';
import { checkSession } from './api/auth';
import { CsrfTokenProvider } from './context/CsrfTokenProvider';
import ThankYou from './pages/ThankYou/ThankYou';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfOnlineSale from './pages/TermsOfOnlineSale/TermsOfOnlineSale';
import LandingPage from './pages/LandingPage/LandingPage';
import ResellerRegister from './pages/ResellerRegister/ResellerRegister';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Contact from './pages/Contact/Contact';
import ResellerActivation from './pages/ResellerActivation/ResellerActivation';
import UnderConstruction from './pages/UnderConstruction/UnderConstruction';



function App() {
  const location = useLocation();
  // Conditionally render Navbar only if the path is not "/reseller/login"
  const hideNavbarPaths = ['/reseller/login', '/reseller/register', '/reseller/dashboard', '/reseller/forgot-password', '/reseller/reset-password'];
  const showNavbar = !hideNavbarPaths.includes(location.pathname);

  const navigate = useNavigate();



  return (
    <div className="App">
      {/* {showNavbar && <Navbar />} */}
    
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/" element={<UnderConstruction />} />
        <Route path="/activation" element={<Activation />} />
        <Route path="/manage-playlist" element={<ManagePlaylist />} />
        <Route path="/reseller/login" element={<LoginPage />} />
        <Route path="/reseller/description" element={<Description />} />
        <Route path="/reseller/register" element={<ResellerRegister />} />
        <Route path="/reseller/dashboard" element={<ResellerDashboard />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfOnlineSale />} />
        <Route path="/reseller/forgot-password" element={<ForgotPassword />} />
        <Route path="/reseller/reset-password" element={<ResetPassword />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reseller/activate/:token" element={<ResellerActivation />} />

      
      </Routes>
      {showNavbar && <Footer />}

    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <CsrfTokenProvider>
        <App />
      </CsrfTokenProvider>

    </Router>
  );
}
