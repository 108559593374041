import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ResellerDashboard.css';
import Sidebar from '../../components/Layout/Sidebar';
import Header from '../../components/Layout/Header';
import Content from '../../components/Layout/Content';

import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { fetchResellerInfo } from '../../api/reseller';
import { checkSession } from '../../api/auth';
import Spinner from '../../components/Spinners/Spinner';

function ResellerDashboard() {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('Customers');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [resellerInfo, setResellerInfo] = useState({ name: '', credits: 0 });
  const [infoLoading, setInfoLoading] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const [isSessionValidated, setIsSessionValidated] = useState(false); // Track validation status
  const [isLoadingSession, setisLoadingSession] = useState(true);

  const { execute: fetchInfo } = useApiWithCsrf(fetchResellerInfo);

  const hasLoaded = useRef(false);

  // Function to load reseller info
  const loadResellerInfo = async () => {
    console.log('load infos');

    setInfoLoading(true);
    try {
      const data = await fetchInfo();
      setResellerInfo({ name: data.name, credits: data.credits });
    } catch (error) {
      console.error('Failed to fetch reseller info:', error);
    } finally {
      setInfoLoading(false);
    }
  };

  useEffect(() => {
    if (!hasLoaded.current) {
      loadResellerInfo();
      hasLoaded.current = true;
    }
  }, [resellerInfo.credits]);


  // Function to verify session and redirect if invalid
  const verifySession = async () => {
    try {
      const response = await checkSession()
      console.log(response);
      
      const { isAuthenticated } = response?.data || null
      setIsSessionValidated(isAuthenticated)
      if (isAuthenticated) {
        console.log('authen : ', isAuthenticated);
        setisLoadingSession(false)
        // navigate('/reseller/dashboard');
      }
      else {
        console.log('authen : ', isAuthenticated);
        setisLoadingSession(false)
        navigate('/reseller/login');
      }
    } catch (error) {
      // console.error('Session validation failed:', error);
      console.log('Session validation failed');
      setisLoadingSession(false)
      navigate('/reseller/login'); // Redirect to login on error
    }
  };

  useEffect(() => {
    // Listen for visibility change events
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        verifySession(); // Check session when tab becomes active
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initial session check on load
    verifySession();

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  return (
    <div>
      {(isSessionValidated && !isLoadingSession) ? (
        <div className="dashboard">
          <button className="toggle-button" onClick={toggleSidebar}>
            ☰
          </button>
          <Sidebar isSidebarOpen={isSidebarOpen} onSelectSection={setSelectedSection} setIsSidebarOpen={setIsSidebarOpen} />
          <div className="dashboard-main">
            <Header
              title={selectedSection}
              name={infoLoading ? 'Loading...' : resellerInfo.name}
              credits={infoLoading ? 'Loading...' : resellerInfo.credits}
            />
            <Content
              section={selectedSection}
              reloadResellerInfo={loadResellerInfo} // Pass the function to reload info
            />
          </div>
          {isSidebarOpen && <div className="menu-overlay" onClick={toggleSidebar}></div>}
        </div>)
        : (
          <center>
            <Spinner />
          </center>
        )}
    </div>

  );
}

export default ResellerDashboard;
