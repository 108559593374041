import React from 'react';
import underConstructionImage from '../../assets/icons/under-construction.jpg';

const UnderConstruction = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f8f9fa',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
    }}>
      <div style={{
        maxWidth: '600px',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#ffffff',
      }}>
        <img 
          src={underConstructionImage}
          alt="Under Construction" 
          style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
        />
        <h1 style={{ color: '#333', fontSize: '2rem', marginBottom: '10px' }}>
          Website Under Construction
        </h1>
        <p style={{ color: '#555', fontSize: '1rem', lineHeight: '1.5' }}>
          We're currently working on improvements to provide you with the best experience. 
          Stay tuned — we'll be live soon!
        </p>
      </div>
    </div>
  );
};

export default UnderConstruction;
