// import React, { createContext, useContext, useState, useEffect } from 'react';
// import axios from 'axios';

// // Create a Context to store the CSRF token globally
// const CsrfTokenContext = createContext(null);
// const backendUrl = process.env.REACT_APP_BACKEND_URL;

// // Custom hook to access the CSRF token from context
// export const useCsrfToken = () => {
//   return useContext(CsrfTokenContext);
// };

// // Provider component that fetches and provides the CSRF token
// export const CsrfTokenProvider = ({ children }) => {
//   const [csrfToken, setCsrfToken] = useState(null);

//   useEffect(() => {
//     const fetchCsrfToken = async () => {
//       try {
//         // Fetch the CSRF token from the backend
//         const response = await axios.get(`${backendUrl}/api/csrf-token`, { withCredentials: true });
//         setCsrfToken(response.data.csrfToken); // Store the token in state
//       } catch (error) {
//         console.error('Failed to fetch CSRF token:', error);
//       }
//     };

//     // Only fetch the token if it's not already set
//     if (!csrfToken) {
//       fetchCsrfToken();
//     }
//   }, [csrfToken]);

//   // Provide the CSRF token to any child components
//   return (
//     <CsrfTokenContext.Provider value={csrfToken}>
//       {children}
//     </CsrfTokenContext.Provider>
//   );
// };

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const CsrfTokenContext = createContext(null);
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Custom hook to access the CSRF token from context
export const useCsrfToken = () => {
  return useContext(CsrfTokenContext);
};

// Provider component that fetches and provides the CSRF token
export const CsrfTokenProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/csrf-token`, { withCredentials: true });
        const token = response.data.csrfToken;
        setCsrfToken(token);

        // Set CSRF token in axios defaults
        axios.defaults.headers.common['X-CSRF-Token'] = token;

        console.log('✅ CSRF Token Set:', token);
      } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
      } finally {
        setLoading(false); // Set loading to false
      }
    };

    fetchCsrfToken();
  }, []);

  // Render children only when the CSRF token is fetched
  if (loading) {
    return <div>Loading...</div>; // Or a custom loading spinner
  }

  return <CsrfTokenContext.Provider value={csrfToken}>{children}</CsrfTokenContext.Provider>;
};

