
import axios from 'axios';
import { apiRequest } from './apiRequest';

// Fetch reseller information
export const clientPaymentStatus = async ( email, macAddress, plan ) => {
    console.log(email, macAddress, plan);
    const response = await apiRequest('post', '/payment-status',{ email, macAddress, plan });
    return response.data;
  };

  export const checkClientMac = async (macAddress ) => {
    const response = await apiRequest('get', `/clients/check/${macAddress}`);
    return response.data;
  };




  export const activateClientMac = async (mac , selected_plan , expant , paymentData ) => {
    const response = await apiRequest('post', `/clients/activate` , {mac , selected_plan , expant , paymentData});
    return response.data;
  };


  export const deleteClientPlaylist = async (macAddress , playlistId  ) => {
    const response = await apiRequest('delete', `/clients/${macAddress}/playlists/${playlistId}` );
    return response.data;
  };

  export const addClientPlaylist = async (macAddress  , newPlaylist ) => {
    const response = await apiRequest('post', `/clients/${macAddress}/playlists` , newPlaylist );
    return response.data;
  };

  export const updateClientPlaylist = async (macAddress , playlistId , data ) => {
    const response = await apiRequest('put', `/clients/${macAddress}/playlists/update/${playlistId}` , data );
    return response.data;
  };


  export const fetchClientPlaylist = async (macAddress ) => {
    const response = await apiRequest('get', `/clients/${macAddress}/playlists` );
    return response.data;
  };